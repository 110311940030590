import { Wistia } from 'wistia_namespace.js';

if (Wistia.engines == null) {
  Wistia.engines = {};
}

const defineEngine = (name, klass) => {
  Wistia.engines[name] = klass;
};

export default defineEngine;
